import * as React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import './global.css'
import { SiteSettingsQuery } from '../generated/graphql'

const Seo = ({
  description = '',
  lang = 'en',
  meta = [],
  title,
  image = undefined,
}: {
  title: string
  image?: string
  meta?: any[]
  lang?: 'en' | 'ch'
  description?: string
}) => {
  const { site, siteSetting }: SiteSettingsQuery = useStaticQuery(
    graphql`
      query SiteSettings {
        site {
          siteMetadata {
            title
            description
          }
        }
        siteSetting: contentfulSiteSettings(contentfulid: { eq: "main" }) {
          id
          favicon {
            file {
              url
            }
          }
          internationalization
        }
      }
    `
  )

  const metaDescription = description || site!.siteMetadata!.description!
  const defaultTitle = site!.siteMetadata?.title!

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      link={[
        {
          rel: 'icon',
          type: 'image/png',
          href: siteSetting!.favicon!.file!.url!,
        },
      ]}
      title={title}
      defaultTitle={defaultTitle}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : undefined}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `image`,
          content: image,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: image,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  )
}

export default Seo
