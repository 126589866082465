import React, { useState } from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { WindowLocation } from '@reach/router'

import { NavBarQueryQuery } from '../generated/graphql'
import { Toggle } from './Toggle'

const Navigation = ({
  location,
  dark = false,
  locale = 'en-US',
}: {
  location: WindowLocation
  dark?: boolean
  locale?: string
}) => {
  const dataOut: NavBarQueryQuery = useStaticQuery(graphql`
    query NavBarQuery {
      zh: contentfulHeader(
        contentfulid: { eq: "main" }
        node_locale: { eq: "zh" }
      ) {
        headerImage {
          file {
            url
          }
        }
        headerImageDark {
          file {
            url
          }
        }
        headerTabs {
          link
          headerText
        }
        websiteName
      }
      en: contentfulHeader(
        contentfulid: { eq: "main" }
        node_locale: { eq: "en-US" }
      ) {
        headerImage {
          file {
            url
          }
        }
        headerImageDark {
          file {
            url
          }
        }
        headerTabs {
          link
          headerText
        }
        websiteName
      }
      siteSetting: contentfulSiteSettings(contentfulid: { eq: "main" }) {
        id
        favicon {
          file {
            url
          }
        }
        internationalization
      }
    }
  `)

  const data = locale === 'en-US' ? dataOut.en! : dataOut.zh!
  const showInternationalization = dataOut.siteSetting?.internationalization

  const [isHidden, setIsHidden] = useState(true)
  return (
    <nav
      role="navigation"
      className="sticky top-0 flex w-full pt-6 pb-6 h-32 flex-col z-10 bg-bg"
      aria-label="Main"
    >
      <div className="flex">
        <Link
          to={locale === 'en-US' ? '/' : '/' + locale}
          className="self-center flex-none flex no-underline pl-4"
        >
          <img
            src={
              dark
                ? data.headerImageDark?.file?.url!
                : data.headerImage?.file?.url!
            }
            className="w-16 object-cover"
          />
          <div className="self-center">
            {data.websiteName!.split('\\n').map((el: string) => (
              <p key={el} className="font-bold text-2xl text-bg dark:text-white pl-4 leading-7">
                {el}
              </p>
            ))}
          </div>
        </Link>
        <div className="hidden flex-auto justify-end align-middle dark:text-white lg:flex ">
          {data.headerTabs!.map((el, i) => (
            <Link
              key={el?.link ?? i}
              to={`${locale === 'en-US' ? '' : '/' + locale}${el?.link!}`}
              className={`ml-7 mr-7 pr-1 pl-1 self-center text-bg dark:text-white text-lg ${
                isActive(location.pathname, el?.link!)
                  ? 'underline'
                  : 'no-underline'
              }`}
            >
              {el?.headerText}
            </Link>
          ))}
        </div>
        <div className="mx-4 inline-flex justify-end dark:text-white content-center align-middle items-center flex-1 lg:flex-none">
          {showInternationalization && (
            <a
              href={
                locale === 'en-US'
                  ? `/zh${location.pathname}`
                  : location.pathname.substring(
                      location.pathname.indexOf('/', 1)
                    )
              }
              className="text-white no-underline"
            >
              <Toggle locale={locale} />
            </a>
          )}
        </div>
        <div className="lg:hidden flex items-center justify-end align-middle pr-4 flex-none">
          <button
            className="outline-none fill-bg dark:fill-white"
            onClick={() => setIsHidden(!isHidden)}
          >
            <svg
              width="20"
              height="12"
              viewBox="0 0 20 12"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 1C0 0.447715 0.447715 0 1 0H19C19.5523 0 20 0.447715 20 1C20 1.55228 19.5523 2 19 2H1C0.447715 2 0 1.55228 0 1ZM6 6C6 5.44772 6.44772 5 7 5H19C19.5523 5 20 5.44772 20 6C20 6.55228 19.5523 7 19 7H7C6.44772 7 6 6.55228 6 6ZM13 10C12.4477 10 12 10.4477 12 11C12 11.5523 12.4477 12 13 12H19C19.5523 12 20 11.5523 20 11C20 10.4477 19.5523 10 19 10H13Z"
              />
            </svg>
          </button>
        </div>
      </div>
      <div className={isHidden ? 'hidden' : ' bg-bg w-full px-4'}>
        {data.headerTabs!.map((el, i) => (
          <Link
            key={el?.link ?? i}
            to={`${locale === 'en-US' ? '' : '/' + locale}${el?.link!}`}
            className={`block text-md py-6 hover:text-accent transition duration-300 dark:text-white text-lg ${
              isActive(location.pathname, el?.link!)
                ? 'underline'
                : 'no-underline'
            }`}
          >
            {el?.headerText}
          </Link>
        ))}
      </div>
    </nav>
  )
}

export default Navigation

const isActive = (currentPath: string, pathName: string) => {
  return false
}
