import React from 'react'

export const ErrorComponent = ({ message }: { message: string }) => {
  return (
    <div className="container mx-auto">
      <h1>Error</h1>
      <h3>Sorry, this one is our fault.</h3>
      <p>{message}</p>
    </div>
  )
}
