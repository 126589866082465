import React from 'react'

export const Toggle = ({ locale = 'en-US' }: { locale?: String }) => {
  return (
    <div className="rounded-3xl border-2 border-bg dark:border-white w-auto inline-flex">
      <button
        className={`${
          locale === 'en-US'
            ? 'bg-bg text-white dark:bg-white dark:text-bg'
            : 'text-bg dark:text-white rounded-full'
        } rounded-full p-1 w-10 h-10 text-lg`}
      >
        EN
      </button>
      <button
        className={`${
          locale === 'zh'
            ? 'bg-bg text-white dark:bg-white dark:text-bg'
            : 'text-bg dark:text-white rounded-full '
        } rounded-full p-1 w-10 h-10 text-lg`}
      >
        中
      </button>
    </div>
  )
}
